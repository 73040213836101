* {
  box-sizing: border-box;
  margin: 0;
}

:root {
  --black: #000000;
  --white: #ffffff;
  --gray-light: #9E9E9E;
  --gray-regular: #3E3E3E;
  --gray-medium: #2C2C2C;
  --gray-dark: #1E1E1E;
  --green-light: #D0FF93;
  --green-regular: #637B72;
  --green-medium: #0E3826;
  --green-dark: #001A15;
  --green-black: #020C0A;
  --green-accent: #00FF22;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100dvh;
  padding: 32px;

  background-color: var(--black);
  color: var(--white);
  font-family: 'Source Sans 3';
  font-size: 1rem;

  display: flex;

  overflow: hidden;
}

h1,
h2,
h3,
h4 {
  font-weight: 100;
  line-height: 130%;
}

h1 {
  font-size: 4rem;
}

.h-highlight {
  color: var(--green-accent);
  font-style: italic;
}

h2 {
  font-size: 2rem;
}

p {
  color: var(--green-regular)
}

a {
  all: unset;
}

button {
  all: unset;
  font-weight: 600;
  font-size: 1rem;
  padding-inline: 16px;
  padding-block: 8px;
  background-color: var(--green-light);
  color: var(--green-black);
  border-radius: 2px;
  transition: background-color 300ms ease-in-out;
}

button:hover {
  cursor: pointer;
  background-color: var(--white);
}

label {
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0.33rem;
  text-transform: uppercase;
  color: var(--green-regular);
}

#root {
  flex: 1;
  display: flex;
  justify-content: center;
}

.main-wrapper {
  flex: 1;
  max-width: 1280px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 32px;
}

.side-bar {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 0.8rem;
}

.terminal {
  height: 320px;
  border: 1px solid var(--gray-regular);
  border-radius: 4px;
  overflow: hidden;
}

.t-window {
  padding: 8px;
  font-family: 'Source Code Pro';
  font-weight: 600;
}

.t-line {
  padding-inline: 16px;
  padding-block: 8px;
  display: flex;
  gap: 8px;
}

.t-cursor {
  width: 8px;
  background-color: var(--white);
  animation: blink 800ms step-start infinite;
}

.t-nav {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2px;
}

.t-nav-link {
  padding-inline: 16px;
  padding-block: 4px;
  border-radius: 2px;
  transition: background-color 300ms ease-in-out;
}

.t-nav-link:hover {
  cursor: pointer;
  background-color: var(--gray-dark);
}

.t-nav-link.active {
  background-color: var(--green-dark);
  color: var(--green-accent);
}

.screen {
  background-color: var(--green-black);
  border: 1px solid var(--green-medium);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: var(--green-regular) transparent;
}

.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero {
  padding: 80px;
  border-bottom: 1px solid var(--green-medium);
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  background-image: url(./assets/pixel.jpg);
  background-size: 8px;
  background-repeat: repeat;
  background-position: center;
}

.hero>h1,
.hr-small>h1 {
  position: relative;
  max-width: 600px;
  text-align: center;
  border: 2px dashed var(--green-dark);
  background-color: var(--green-black);
}

.hr-small {
  padding: 48;
  gap: 8px;
}

.hr-small>h1 {
  font-size: 3rem;
}

.hero-accent {
  color: var(--green-accent);
  font-style: italic;
}

section {
  padding-inline: 48px;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 48px;
  border-bottom: 2px dashed var(--green-dark);
}

section:last-child {
  border: none;
}

.section-heading {
  padding-block: 80px;
  border-inline: 2px dashed var(--green-dark);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.section-content {
  padding-block: 80px;
  border-inline: 2px dashed var(--green-dark);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.section-illustration {
  width: 100%;
}

.image-container {
  width: 100%;
  aspect-ratio: 4/3;
  background-color: var(--green-dark);
  overflow: hidden;
}

.articles-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  /* Margin fixes overlapping border */
  margin-right: -2px;
}

.article-card {
  padding: 40px;
  border-bottom: 2px dashed var(--green-dark);
  border-right: 2px dashed var(--green-dark);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
}

.ac-top {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* Utilities */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.copyright {
  color: var(--gray-regular);
}

/* Breakpoints */

@media (max-width: 1024px) {
  body {
    padding: 16px;
  }

  .main-wrapper {
    gap: 16px;
  }

  .hero {
    padding: 48px;
  }

  h1 {
    font-size: 3rem;
  }

  .hr-small>h1 {
    font-size: 2.5rem;
  }

  .hero>h1,
  .hr-small>h1 {
    max-width: 500px;
  }

  .articles-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  body {
    padding: 8px;
  }

  .main-wrapper {
    gap: 8px;
    grid-template-columns: 1fr 1.8fr;
  }

  .hero {
    padding: 24px;
    gap: 16px;
  }

  .hr-small {
    gap: 8px;
  }

  .hero>h1,
  .hr-small>h1 {
    max-width: 350px;
  }

  h1 {
    font-size: 2rem;
  }

  .hr-small>h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  section {
    padding-inline: 24px;
    gap: 8px;
  }

  .section-heading {
    padding-block: 24px;
  }

  .section-content {
    padding-block: 24px;
  }

  .articles-grid {
    grid-template-columns: 1fr 1fr;
  }

  .t-window {
    padding: 4px;
  }

  .t-line {
    padding-inline: 8px;
  }

  .t-nav-link {
    padding-inline: 8px;
  }

  .article-card {
    padding: 24px;
    gap: 16px;
  }
}

@media (max-width: 450px) {
  body {
    padding: 2px;
  }

  .main-wrapper {
    gap: 2px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 12fr;
  }

  .copyright {
    display: none;
  }

  .hero {
    padding: 16px;
  }

  .hero>h1,
  .hr-small>h1 {
    max-width: 300px;
  }

  section {
    padding-inline: 16px;
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .section-content {
    padding-block: 16px;
  }

  .section-heading {
    padding-block: 16px;
    padding-bottom: 0px;
  }

  .articles-grid {
    display: flex;
    flex-direction: column;
  }

  .article-card {
    padding: 16px;
  }
}

/* Animations */

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}